import React, {useState, useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { FaCircleArrowRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './zdjecia.css';
import { collection, getFirestore, getDocs, query, where } from "firebase/firestore";
import DropzoneComponent from './DropzoneComponent';
import { useAuth } from '../Login/useAuth'

const PhotoMenu = () => {
    const {adminUser} = useAuth()
    const db = getFirestore();
    const photosRef = collection(db, "photos");
    const catalogRef = collection(db, "catalogs")
    const [catalogs, setCatalogs] = useState({})
    useEffect(() => {
        getDocument()
    }, [])
    const getDocument = async () => {
        try {
            const catalogsSnapshot = await getDocs(catalogRef);            
            const catalog_dict = {};  
            for (const docSnapshot of catalogsSnapshot.docs) {
                const photosQuery = query(photosRef, where("catalogId", "==", docSnapshot.id));
                const photosSnapshot = await getDocs(photosQuery);
    
                const folderName = docSnapshot.data().folderName;
                const folderId = docSnapshot.id;
    
                if (!(folderName in catalog_dict)) {
                    catalog_dict[folderName] = []; // Initialize array if it doesn't exist
                }
                if (photosSnapshot.empty) {
                    catalog_dict[folderName].push({ url: "" , id: folderId });
                } else {
                    for (const photoSnapshot of photosSnapshot.docs) {
                        catalog_dict[folderName].push({ url: photoSnapshot.data().fileUrl, id: folderId});
                    }
                }
            }
            
            setCatalogs(catalog_dict);
    
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    };
    

    return (
        <div className="photo-menu">
            <ImageList sx={{ width: '100%', marginTop: '0', overflow: 'hidden',gap: '0 !important'}} rowHeight={500} >
                {Object.keys(catalogs).map((item, index) => (
                    <Link to={`/zdjecia/${catalogs[item][0].id}`}>
                        <ImageListItem key={`${index}-${item}`} >
                            <img
                                className="photo-menu-img"
                                srcSet={`${catalogs[item][0].url ||""}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                src={`${catalogs[item][0].url|| "" }?w=248&fit=crop&auto=format`}
                                alt={item}
                                loading="lazy"
                                style={{maxHeight:'500px'}}
                            />
                            <ImageListItemBar
                                title={item}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.id}`}
                                    >
                                        <div className='image-list-inside'>
                                            <Link to={`/zdjecia/${catalogs[item][0].id}`}>
                                                <FaCircleArrowRight color="#fff"/>
                                            </Link>
                                        </div>
                                    </IconButton>
                                }
                            
                            />
                        </ImageListItem>
                    </Link>
                ))}
            </ImageList>
            {adminUser &&
                <DropzoneComponent catalogOnly={true} title="Dodaj nowy folder" callbackFunc={async () => {getDocument()}}/>
            }
        </div>
    );
};

export default PhotoMenu;
