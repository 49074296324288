import React, { useState, useEffect } from 'react';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { collection, doc, getDocs, getFirestore, getDoc, query, where, updateDoc, deleteDoc } from "firebase/firestore";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import './zdjecia.css';
import Navbar from '../navbar/Navbar';
import oferta from '../../assets/zdjecia.jpeg'
import mobileimage from '../../assets/zdjecia-mobile.jpeg'
import { FaRegEdit } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import DropzoneComponent from "./DropzoneComponent";
import Footer from "../Footer/Footer"
import { IoIosCloseCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Login/useAuth'

const PhotoGallery = () => {
  const {adminUser} = useAuth()
  const { folderId} = useParams();
  const [photos, setPhotos] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [folderName, setFolderName] = useState("");
  const [notification, setNotification] = useState("")
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1200px)');
  const isDesktop = useMediaQuery('(min-width:1201px)');

  const getCols = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    if (isDesktop) return 3;
  };

  const getCatalogInfo = async () => {
    const catalogDocRef = doc(db, "catalogs", folderId);
    const catalogDoc = await getDoc(catalogDocRef)
    if(catalogDoc.exists()){
      const catalogData = catalogDoc.data();
      setCatalog({ id: folderId, ...catalogData });
      setFolderName(catalogData.folderName)
      setNewDescription(catalogData.description);
    }
  };
  

  const deletePhoto = async (id) => {
    try {
      const photoDocRef = doc(db, "photos", id);
      const photoDoc = await getDoc(photoDocRef);
  
      if (photoDoc.exists()) {
        const photoUrl = photoDoc.data().fileUrl;
        const photoRef = ref(storage, photoUrl);
  
        await deleteObject(photoRef);
        await deleteDoc(photoDocRef);

        const photosRef = collection(db, "photos");
        const querySnapshot = await getDocs(query(photosRef, where("catalogId", "==", catalog.id)));
  
        if (querySnapshot.empty) {
          const catalogDocRef = doc(db, "catalogs", catalog.id);
          await deleteDoc(catalogDocRef);
            console.log("Catalog and folder deleted as it had no photos left.");
          navigate('/zdjecia');
        } else {
          getDocument();
        }
        console.log("Photo deleted successfully");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error deleting photo: ", error);
    }
  };

  useEffect(() => {
    getCatalogInfo();
  }, []);

  useEffect(() => {
    if (catalog.id) {
      getDocument();
    }
  }, [catalog.id]);

  const editDescription = async () => {
    const catalogRef = collection(db, "catalogs");
    const catalogDocRef = doc(catalogRef, catalog.id);
    const catalogDoc = await getDoc(catalogDocRef);
    if (catalogDoc.exists()) {
      await updateDoc(catalogDocRef, {
        description: newDescription
      });
      setCatalog((prevCatalog) => ({ ...prevCatalog, description: newDescription }));
      setIsEditing(false);
    }
  };

  const getDocument = async () => {
    try {
      const photosRef = collection(db, "photos");
      const querytest = query(photosRef, where("catalogId", "==", catalog.id));
      const querySnapshot = await getDocs(querytest);

      const photoDocs = [];

      for (const docSnapshot of querySnapshot.docs) {
        const data = docSnapshot.data();
        photoDocs.push({url: data.fileUrl, id: docSnapshot.id});
      }

      setPhotos(photoDocs);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };

  const handleOpenModal = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedPhotoIndex(null);
  };

  const handlePrevPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1));
  };

  const handleNextPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex < photos.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <>
    <div className="photo-menu">
      <Navbar />
      <div className="img-container">
        <img src={oferta} alt="soar" className="img-main" />
        <div className="overlay-text">
          Zdjęcia realizacji
        </div>
        <div className="overlay-text-mobile">
          <img src={mobileimage} alt="soar" className="img-main-mobile" />
        </div>
      </div>
      <div className="photo-header">
        <h1>{catalog.folderName}</h1>
        <div className="photo-header-description">
          {isEditing ? (
              <form
              onSubmit={(e) => {
                e.preventDefault();
                editDescription();
              }}
              >
              <input
                type="text"
                className="input-description"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                autoFocus
              />
              <IconButton type="submit">
                <FaSave color="black"/>
              </IconButton>
              </form>
          ) : (
            <div className="photo-header-description-text">
              <p>{catalog.description}</p>
              {adminUser &&
              <FaRegEdit className="photo-header-description-text-icon" onClick={() => setIsEditing(true)} />
              }
            </div>
          )}
        </div>
      </div>
      <p>{notification}</p>
      <ImageList sx={{ width: '100vw', gap: '0 !important'}} cols={getCols()}>
  {photos.map((item, index) => (
    <div className='gallery-img-container' key={index}>
      { adminUser &&
        <IoIosCloseCircle className='gallery-img-container-close' onClick={() => { deletePhoto(item.id) }} />
      }
      <ImageListItem sx={{ height: '500px' }}>
        <img
          srcSet={`${item.url}?w=248&h=500&fit=crop&auto=format&dpr=2 2x`}
          src={`${item.url}?w=248&h=500&fit=crop&auto=format`}
          alt='zdjecie-realizacji'
          loading="lazy"
          onClick={() => handleOpenModal(index)}
          style={{
            minHeight: '500px',
            minWidth: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </ImageListItem>
    </div>
  ))}
</ImageList>


      <Modal
        open={selectedPhotoIndex !== null}
        onClose={handleCloseModal}
        aria-labelledby="photo-modal"
        aria-describedby="photo-modal-description"
        className="modal"
      >
        <div className="modal-content">
          {selectedPhotoIndex !== null && photos[selectedPhotoIndex].url && (
            <>
              <div className="modal-close-container">
                <IconButton onClick={handleCloseModal} className="modal-close-button">
                  <CloseIcon color='#fff' />
                </IconButton>
              </div>
              <img
                src={photos[selectedPhotoIndex].url}
                className="modal-photo"
                alt="modal-photo"
              />

              <div className="modal-controls">
                <IconButton onClick={handlePrevPhoto} className="modal-prev-button">
                  <ArrowBackIcon color='#fff' />
                </IconButton>
                <IconButton onClick={handleNextPhoto} className="modal-next-button">
                  <ArrowForwardIcon color='#fff' />
                </IconButton>
              </div>
            </>
          )}
        </div>
      </Modal>
      {
        adminUser &&
        <div className='dragndrop-container'>
          <DropzoneComponent title="Dodaj nowe zdjęcia" catalog={folderName} callbackFunc={() => {getDocument()}}/>
        </div>
      }
    </div>
    <Footer style={{marginTop: '10em'}}/>
    </>
  );
};

export default PhotoGallery;
