import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { getFirestore, collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { storage } from './../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const DropzoneComponent = ({catalogOnly=false, catalog="", callbackFunc=()=>{}, title}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState('');
  const [folderName, setFolderName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) return;

    const allowedExtensions = ['jpg', 'jpeg', 'png', 'heic'];
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      alert('Tylko rozszerzenia (jpg, jpeg, png, heic) są dozwolone');
      return;
    }

    setSelectedFile(file);
  }, []);

  useEffect(() => {
    if (!catalogOnly && catalog !== ""){
      setFolderName(catalog)
    }
  },[catalog, catalogOnly])
  const uploadFile = async () => {
    if (!selectedFile && !catalogOnly) {
      alert('Nie wybrano pliku...');
      return;
    }
    if (!folderName) {
      alert('Nazwa folderu nie została podana...');
      return;
    }

    const db = getFirestore();

    try {
      const catalogRef = collection(db, "catalogs");
      const catalogQuery = query(catalogRef, where("folderName", "==", folderName));
      const catalogSnapshot = await getDocs(catalogQuery);

      let catalogId;

      if (catalogSnapshot.empty) {
        const newCatalogRef = await addDoc(catalogRef, {
          folderName: folderName,
          description: "Dodaj opis dla tej realizacji...",
        });
        catalogId = newCatalogRef.id;
      } else {
        catalogId = catalogSnapshot.docs[0].id;
      }
      if(catalogOnly){
        callbackFunc()
        return
      }
      const storageRef = ref(storage, `${folderName}/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      setUploading(true);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFileUrl(downloadURL);

          await addDoc(collection(db, "photos"), {
            fileUrl: downloadURL,
            catalogId: catalogId,
            timestamp: new Date(),
          });

          console.log("Photo added to Firestore with catalogId: ", catalogId);
          callbackFunc()
          setUploading(false);
        }
      );
    } catch (error) {
      console.error("Error in uploading file or adding document:", error);
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/heic': ['.heic'],
    },
  });

  const handleFolderChange = (e) => {
    setFolderName(e.target.value);
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    uploadFile();
  };

  return (
    <div className="dropzone-component">
      <h2>{title}</h2>
      <form onSubmit={handleSubmit}>
        { catalogOnly &&
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="folderName">Nazwa folderu:</label>
            <input
              type="text"
              id="folderName"
              value={folderName}
              onChange={handleFolderChange}
              required
            />
          </div>
        }
        { !catalogOnly &&
        <div className={`dragndrop ${isDragActive ? 'active' : ''}`}>
          <div className='dragndrop-dashed' {...getRootProps()}>
            <input {...getInputProps()} />
            <p>
              {selectedFile
                ? `Wybrany plik: ${selectedFile.name}`
                : isDragActive
                ? 'Upuść plik tutaj...'
                : 'Przeciągnij i upuść plik tutaj, lub kliknij aby wybrać plik'}
            </p>
          </div>
        </div>
        }
        <button
          type="submit"
          disabled={uploading || (!selectedFile && !catalogOnly) || !folderName}
          className={uploading || (!selectedFile && !catalogOnly) || !folderName ? 'disabled' : ''}
        >
          {uploading ? 'Uploading...' : 'Dodaj'}
        </button>
      </form>

      {uploading && (
        <div className="uploading-progress">
          <p>Uploading... {Math.round(progress)}%</p>
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}

      {fileUrl && (
        <div className="upload-success">
          <p>Plik został pomyślnie przesłany!</p>
        </div>
      )}
    </div>
  );
};

export default DropzoneComponent;
